import * as THREE from "three";

export default function loadTexture(url) {
  return new Promise((resolve, reject) => {
    const loader = new THREE.TextureLoader();

    loader.load(
      url, // URL of the texture to load
      (texture) => {
        // onLoad callback
        resolve(texture); // Resolve the promise with the loaded texture
      },
      undefined, // onProgress callback not needed here
      (error) => {
        // onError callback
        reject(error); // Reject the promise on error
      }
    );
  });
}
