import * as THREE from "three";
import * as CANNON from "cannon-es";

export default class Obstacle {
  constructor(radius, position) {
    // Create a Three.js sphere mesh
    const geometry = new THREE.SphereGeometry(radius, 32, 32);
    const material = new THREE.MeshNormalMaterial();
    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.position.set(position.x, position.y, position.z);

    // Create a Cannon.js sphere body
    const shape = new CANNON.Sphere(radius);
    this.body = new CANNON.Body({
      mass: 1, // Set the mass to 1 (or whatever is appropriate for your simulation)
      position: new CANNON.Vec3(position.x, position.y, position.z),
    });
    this.body.addShape(shape);

    // Sync Three.js mesh with Cannon.js body position and rotation
    this.update = function () {
      this.mesh.position.copy(this.body.position);
      this.mesh.quaternion.copy(this.body.quaternion);
    };
  }
}
