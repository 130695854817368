import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

export default function getBackgroundEnvMap(
  scene,
  renderer,
  hdrPath = "/meadow_2_2k.hdr"
) {
  // Create a new instance of RGBELoader
  const rgbeLoader = new RGBELoader();
  rgbeLoader.setDataType(THREE.UnsignedByteType); // Or .setDataType(THREE.FloatType) for higher quality

  rgbeLoader.load(hdrPath, function (texture) {
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    const envMap = pmremGenerator.fromEquirectangular(texture).texture;
    pmremGenerator.dispose();

    // Set the environment map as the scene background
    scene.background = envMap;

    // If you also want to use this as the environment map for materials in the scene, you can set it like so:
    scene.environment = envMap;

    // Remember to dispose of the texture to free up memory
    texture.dispose();
  });
}
