import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as CANNON from "cannon-es";
import cannonDebugger from "cannon-es-debugger";
import Stats from "stats.js";
import Car from "./world/car";
import loadTexture from "./utils/loadTextures";
import Obstacle from "./Obstacle";
import getBackgroundEnvMap from "./utils/getBackgroundEnvMap";
import { FixedAttributionIcon } from "fixed-attribution-icon";
const groundSizeMultiplier = 10;

new FixedAttributionIcon(
  "https://github.com/abhicominin/Car-Controller-Threejs"
);

var stats = new Stats();
stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
document.body.appendChild(stats.dom);
/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");
const scene = new THREE.Scene();
// scene.fog = new THREE.Fog(0xff6000, 300, 500);
scene.background = new THREE.Color(0xff6000);

const world = new CANNON.World({
  gravity: new CANNON.Vec3(0, -9.82, 0), // m/s²
});
world.broadphase = new CANNON.SAPBroadphase(world);
//cannonDebugger(scene, world.bodies, {color: 0x00ff00})

const car = new Car(scene, world);
car.init();

const bodyMaterial = new CANNON.Material();
const groundMaterial = new CANNON.Material();
const bodyGroundContactMaterial = new CANNON.ContactMaterial(
  bodyMaterial,
  groundMaterial,
  {
    friction: 0.1,
    restitution: 0.3,
  }
);
world.addContactMaterial(bodyGroundContactMaterial);

/**
 * Lights
 */
const dirLight = new THREE.DirectionalLight(0xf0997d, 0.8);
dirLight.position.set(-60, 100, -10);
dirLight.castShadow = true;
dirLight.shadow.camera.top = 50;
dirLight.shadow.camera.bottom = -50;
dirLight.shadow.camera.left = -50;
dirLight.shadow.camera.right = 50;
dirLight.shadow.camera.near = 0.1;
dirLight.shadow.camera.far = 200;
dirLight.shadow.mapSize.width = 4096;
dirLight.shadow.mapSize.height = 4096;
scene.add(dirLight);

/**
 * Cube Texture Loader
 */

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

car.onChassisLoaded(() => {
  car.chassis.add(cameraParent);
  camera.position.set(0, 2, -10);
  camera.rotateY((Math.PI / 180) * 180);
});

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Floor
 */
const floorGeo = new THREE.PlaneGeometry(
  100 * groundSizeMultiplier,
  100 * groundSizeMultiplier
);
const floorMesh = new THREE.Mesh(
  floorGeo,
  new THREE.MeshToonMaterial({
    color: 0x454545,
  })
);
floorMesh.position.set(0, -0.34, 0);

let textures = [
  "diff.jpg",
  "disp.jpg",
  "norm_gl.jpg",
  "rough.jpg",
  "ao.jpg",
].map((url) => loadTexture(`/ground_textures/${url}`));
Promise.all(textures).then((textures) => {
  const [diffuseMap, displacementMap, normalMap, roughnessMap, aoMap] =
    textures;

  {
    const repeatAmount = 10 * groundSizeMultiplier;

    diffuseMap.wrapS = diffuseMap.wrapT = THREE.RepeatWrapping;
    diffuseMap.repeat.set(repeatAmount, repeatAmount); // Example: repeat texture 2 times on each axis

    displacementMap.wrapS = displacementMap.wrapT = THREE.RepeatWrapping;
    displacementMap.repeat.set(repeatAmount, repeatAmount);

    normalMap.wrapS = normalMap.wrapT = THREE.RepeatWrapping;
    normalMap.repeat.set(repeatAmount, repeatAmount);

    roughnessMap.wrapS = roughnessMap.wrapT = THREE.RepeatWrapping;
    roughnessMap.repeat.set(repeatAmount, repeatAmount);

    aoMap.wrapS = aoMap.wrapT = THREE.RepeatWrapping;
    aoMap.repeat.set(repeatAmount, repeatAmount);
  }
  // Create a MeshStandardMaterial with the loaded textures
  const material = new THREE.MeshStandardMaterial({
    map: diffuseMap, // Base color texture
    displacementMap: displacementMap, // Displacement texture for height mapping
    normalMap: normalMap, // Normal texture
    roughnessMap: roughnessMap, // Roughness texture
    aoMap: aoMap, // Ambient Occlusion texture
  });
  floorMesh.material = material;
});

floorMesh.rotation.x = -Math.PI * 0.5;
scene.add(floorMesh);

const floorS = new CANNON.Plane();
const floorB = new CANNON.Body();
floorB.mass = 0;

floorB.addShape(floorS);
world.addBody(floorB);

floorB.quaternion.setFromAxisAngle(new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5);

/**
 * Camera
 */
// Base camera

const cameraParent = new THREE.Group();

const camera = new THREE.PerspectiveCamera(
  50,
  sizes.width / sizes.height,
  0.1,
  10000
);
cameraParent.add(camera);
camera.position.set(0, 7, 7);
scene.add(cameraParent);

// Controls
// const controls = new OrbitControls(camera, canvas);
// controls.enableZoom = false;
// controls.enablePan = false;
// w
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const timeStep = 1 / 60; // seconds
let lastCallTime;

// Create an obstacle instance
const obstacles = [];

for (let i = 0; i < 20; i++) {
  const direction = Math.random() > 0.5 ? 1 : -1;
  const obstacle = new Obstacle(1, {
    x: (Math.random() * 25 + 10) * direction,
    y: 5,
    z: (Math.random() * 25 + 10) * direction,
  });
  scene.add(obstacle.mesh);
  world.addBody(obstacle.body);
  obstacles.push(obstacle);
}

// Add the mesh to the Three.js scene

const tick = () => {
  stats.begin();
  // Update controls
  //   if (car.chassis.getWorldPosition) {
  //     // const carPos = car.chassis.getWorldPosition(new THREE.Vector3());
  //     const carPos = car.chassis.position;
  //     controls.target.set(carPos.x, carPos.y, carPos.z);
  //   }
  //   controls.update();

  const time = performance.now() / 1000; // seconds
  if (!lastCallTime) {
    world.step(timeStep);
  } else {
    const dt = time - lastCallTime;
    world.step(timeStep, dt);
  }
  lastCallTime = time;

  // Render
  renderer.render(scene, camera);
  stats.end();

  obstacles.forEach((obstacle) => obstacle.update());

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

let lastMouseX = null; // Store the last mouse X position
let lastMouseY = null; // Store the last mouse X position

document.addEventListener("mousemove", (e) => {
  // Initialize lastMouseX with the current mouse position if it's the first event
  if (lastMouseX === null) {
    lastMouseX = e.clientX;
    return; // Skip the rest of the function on the first call
  }
  if (lastMouseY === null) {
    lastMouseY = e.clientY;
    return; // Skip the rest of the function on the first call
  }

  // Calculate the movement direction
  const deltaX = e.movementX ?? e.clientX - lastMouseX;

  const deltaY = e.movementY ?? e.clientY - lastMouseY;

  const isPointerLocked = e.movementX;
  const isPointerLockedY = e.movementY;

  // Rotate the camera based on the direction
  const degrees =
    (Math.PI / 180) * 1 * ((deltaX < 0 ? 1 : -1) * (isPointerLocked ? 1.5 : 1));
  // Rotate the camera based on the direction

  const degreesY =
    (Math.PI / 180) *
    1 *
    ((deltaX < 0 ? 1 : -1) * (isPointerLockedY ? 1.5 : 1));

  cameraParent.rotateY(degrees); // Rotate camera parent to the left
  //   cameraParent.rotateX(degreesY); // Rotate camera parent to the left
  // Use GSAP to smoothly rotate cameraParent

  // Update lastMouseX for the next event
  lastMouseX = e.clientX;
  lastMouseY = e.clientY;
});

// document.body.style.cursor = "none";

const element = document.body; // Can be any element, often a canvas

element.addEventListener("click", () => {
  element.requestPointerLock();
});

// Listen for the pointer lock change event to know when it's locked or released
document.addEventListener("pointerlockchange", lockChangeAlert, false);

function lockChangeAlert() {
  if (document.pointerLockElement === element) {
    console.log("The pointer lock status is now locked");
    // Do something, e.g., enable game controls
  } else {
    console.log("The pointer lock status is now unlocked");
    // Do something, e.g., pause game controls
  }
}

getBackgroundEnvMap(scene, renderer);
